<template>
  <div class="beta-container">
    <div class="beta-header">
      <div v-if="!$device.mobile" class="beta-header__logo">
        <img
          class="sidebar__logo-img"
          src="@/assets/img/logo_full.svg"
          width="200"
        />
      </div>
      <div class="beta-header__sub-logo">
        <div class="main-title">Beta features</div>
        <div class="sub-title">
          enjoy early access to experimental new features, which may change
          during development
        </div>
      </div>
    </div>

    <div v-if="false" class="empty-container">
      <div class="empty-container__main-text">
        There are no new beta features
      </div>
      <div class="empty-container__sub-text">
        We will reach out as new beta features will become available.
      </div>
    </div>
    <div v-else class="tasks-wrapper">
      <BetaTask
        v-for="(task, taskIndex) in betaTaskList"
        :key="task.id"
        :header-text="task.header"
        :description="task.description"
        :is-select-value="task.isSelected"
        :is-switcher-disabled="task.isDisabled"
        @switcherChangedValue="switcherChangedValueHandler($event, taskIndex)"
      />
    </div>
    <div class="need-help">
      Need help? Reach out to
      <a
        :href="`mailto:support@social-wonder.com?subject=Help%20needed&body=${mailToHref}`"
        >support@social-wonder.com</a
      >
    </div>
  </div>
</template>

<script>
import BetaTask from "@/components/smb-client/BetaTask.vue";
import { mapActions, mapGetters } from "vuex";
import LogRocket from "logrocket";

export default {
  name: "BetaPage",
  components: { BetaTask },
  data: () => ({
    betaTaskList: [
      {
        id: 1,
        header: "Reels (video) creation",
        description:
          "Enable to be able to use our Reel (video) creation feature. During the time of the beta, and for the first 3 months, you will be able to generate and publish as many videos as you like without using any of your credits!",
        isSelected: false,
        isDisabled: false,
        value: "reel",
      },
    ],

    switch1: true,
  }),
  created() {
    LogRocket.track("beta_page_open");
    this.betaTaskList.forEach((betaTask) => {
      if (this.getCurrentSmbCustomer.beta_features.includes(betaTask.value)) {
        betaTask.isSelected = true;
        betaTask.isDisabled = true;
      }
    });
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    mailToHref() {
      const mailBody = `Name: ${
        this.getCurrentSmbCustomer.personal_name || ""
      }\nEmail: ${this.getCurrentSmbCustomer.email || ""}\nPhone: ${
        this.getCurrentSmbCustomer.primary_phone || ""
      }\n`;
      return encodeURI(mailBody);
    },
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomer"]),
    async switcherChangedValueHandler(newValue, taskIndex) {
      this.betaTaskList[taskIndex].isSelected = newValue;
      await this.updateCustomerBetaFeaturesHandler();
    },
    async updateCustomerBetaFeaturesHandler() {
      let selectedFeaturesList = [];
      this.betaTaskList.forEach((task) => {
        if (task.isSelected) {
          selectedFeaturesList.push(task.value);
        } else {
          selectedFeaturesList = selectedFeaturesList.filter(
            (taskValue) => task.value !== taskValue
          );
        }
      });

      try {
        await this.updateCustomer({
          customerId: this.getCurrentSmbCustomer.id,
          params: { beta_features: selectedFeaturesList },
        });
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.beta-container {
  background-color: #f3f2f3;
  height: 100%;
  .beta-header {
    padding: 15px 16px 12px 16px;
    background: #fff;
    display: flex;
    flex-direction: row;
    &__logo {
      margin-right: 50px;
      padding-top: 5px;
    }
    &__sub-logo {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 760px) {
        flex-direction: row;
        align-items: center;
      }
      .main-title {
        margin-right: 30px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
      .sub-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        color: #595a5c;
      }
    }
  }
  .empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50% 10px 0 10px;

    &__main-text {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      padding-bottom: 25px;
    }
    &__sub-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-align: center;
    }

    @media screen and (min-width: 760px) {
      height: 100vh;
      padding: unset;
    }
  }
  .tasks-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    @media screen and (min-width: 760px) {
      padding: 80px 0 0 20px;
    }
    .beta-task:not(:last-child) {
      padding-bottom: 20px;
    }
  }
  .need-help {
    padding: 20px 0 20px 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: #4b4c4e;
    @media screen and (min-width: 760px) {
      padding: 20px 0 0 20px;
      font-size: 14px;
    }
  }
}
</style>

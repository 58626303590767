<template>
  <div class="beta-task">
    <div class="beta-task__header">
      <div class="beta-task__header-text">{{ headerText }}</div>
      <div class="beta-task__switcher">
        <v-switch
          v-model="selectedModel"
          :disabled="isSwitcherDisabled"
          inset
          hide-details
        ></v-switch>
      </div>
    </div>
    <div class="beta-task__description">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BetaTask",
  props: {
    isSelectValue: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    isSwitcherDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedModel: {
      get() {
        return this.isSelectValue;
      },
      set(value) {
        this.$emit("switcherChangedValue", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.beta-task {
  width: 100%;
  max-width: 700px;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
  }
  &__header-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  &__switcher {
    .v-input {
      margin-top: unset !important;
    }
  }
  &__description {
    width: 100%;
    max-width: 520px;
    color: #4b4c4e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
}
</style>
